<template>
  <div class="top_nav_box"><TopNav /></div>
  <div class="home_main">

    <!-- 第1屏 -->
    <transition name="fade">
      <div v-show="currentTab === 0" class="tab_box_1">
        <div class="tab_text1">
          <h1>助力乡村产业发展</h1>
          <p>
            通过数字化让村民随时随地掌握村里动态，如政策变动、项目进展、产业分布等，还能自由分享农产品信息、手工艺作品等，极大地拓宽了销售渠道。实现信息共享，推动产业发展。
          </p>
        </div>
      </div>
    </transition>

    <!-- 第2屏 -->
    <transition name="fade">
      <div v-show="currentTab === 1" class="tab_box_2">
        <div class="tab_text1">
          <h1>开启数字化新生活</h1>
          <p>
            村民可以通过平台了解村里的最新动态、参与线上活动、享受便捷的生活服务，让乡村生活更加智能、高效。<br>可以查看村务信息、参与文化活动、发布互助需求，致力让数字化真正融入乡村生活的每一个角落。
          </p>
        </div>
      </div>
    </transition>

    <!-- 第3屏 -->
    <transition name="fade">
      <div v-show="currentTab === 2" class="tab_box_3">
        <div class="tab_text3">
          <h1>展现乡村魅力</h1>
          <p>
            每个村子可以对外展示本村的特色产业、传统文化、自然风光，吸引游客、投资者和文化爱好者。<br>无论是特色农产品、手工艺品，还是民俗活动、历史故事，都能被更多人看见，提升乡村的知名度和影响力。
          </p>
        </div>
      </div>
    </transition>

    <!-- 第4屏 -->
    <transition name="fade">
      <div v-show="currentTab === 3" class="tab_box_4">
        <div class="tab_text3">
          <h1>信息共享连接</h1>
          <p>
            村民可以通过平台获取最新的政策资讯、产业动态、市场信息，同时也能将自己的需求、资源、产品发布出去，与全国无缝对接。无论是远在他乡的游子，还是外来的投资者，都能与乡村建立紧密联系，实现信息共享、资源互通，共同推动乡村发展。
          </p>
        </div>
      </div>
    </transition>

    <!-- 第5屏 -->
    <transition name="fade">
      <div v-show="currentTab === 4" class="tab_box_5">
        <div class="tab_text3">
          <h1>本地生活服务</h1>
          <p>
            平台提供自由发布信息，如搬家、跑腿、家政、家电维修等，让买家快速找到所需服务，卖家精准触达目标客户，真正实现“买与卖”的便捷对接，让乡村生活更加高效、舒适。
          </p>
        </div>
      </div>
    </transition>

    <!-- 底部 -->
    <div class="tab_bottom">
      <div class="tab_bar">
        <span
        v-for="(tab, index) in tabs"
        :key="index"
        :class="{ selected: currentTab === index }"
        @mouseenter="pauseAutoSwitch(); onTabChange(index)"
        @mouseleave="resumeAutoSwitch">
          {{ tab }}
        </span>
      </div>
      <div class="copyright">
        <a href="http://izzxq.com/" target="_blank">
          <span>© 村兴通 - 深圳智在向前科技有限公司</span>
        </a>
        <a href="https://beian.miit.gov.cn/" target="_blank">
          <span>粤ICP备2022095797号-6</span>
        </a>
      </div>
    </div>
  </div>

  <!-- 移动端 -->
  <div class="h5_main">
    <div class="top_nav_box_h5">
      <img src="../../src/assets/images/logo.svg" />
      <span>乡村信息共享平台</span>
    </div>

    <!-- banner -->
    <div class="banner">
      <div class="banner_img">
        <img v-show="currentTab === 0" src="../../src/assets/images/banner1.jpg" />
        <img v-show="currentTab === 1" src="../../src/assets/images/banner2.jpg" />
        <img v-show="currentTab === 2" src="../../src/assets/images/banner3.jpg" />
        <img v-show="currentTab === 3" src="../../src/assets/images/banner4.jpg" />
        <img v-show="currentTab === 4" src="../../src/assets/images/banner5.jpg" />
      </div>
      <div class="banner_text">
        <!-- 第1屏 -->
        <div v-show="currentTab === 0" class="banner_text_box">
          <h1>助力乡村产业发展</h1>
          <p>通过数字化让村民随时随地掌握村里动态，如政策变动、项目进展、产业分布等，还能自由分享农产品信息、手工艺作品等，极大地拓宽了销售渠道。实现信息共享，推动产业发展。</p>
        </div>

        <!-- 第2屏 -->
        <div v-show="currentTab === 1" class="banner_text_box">
          <h1>开启数字化新生活</h1>
          <p>村民可以通过平台了解村里的最新动态、参与线上活动、享受便捷的生活服务，让乡村生活更加智能、高效。<br>可以查看村务信息、参与文化活动、发布互助需求，致力让数字化真正融入乡村生活的每一个角落。</p>
        </div>

        <!-- 第3屏 -->
        <div v-show="currentTab === 2" class="banner_text_box">
          <h1>展现乡村魅力</h1>
          <p>每个村子可以对外展示本村的特色产业、传统文化、自然风光，吸引游客、投资者和文化爱好者。<br>无论是特色农产品、手工艺品，还是民俗活动、历史故事，都能被更多人看见，提升乡村的知名度和影响力。</p>
        </div>

        <!-- 第4屏 -->
        <div v-show="currentTab === 3" class="banner_text_box">
          <h1>信息共享连接</h1>
          <p>村民可以通过平台获取最新的政策资讯、产业动态、市场信息，同时也能将自己的需求、资源、产品发布出去，与全国无缝对接。无论是远在他乡的游子，还是外来的投资者，都能与乡村建立紧密联系，实现信息共享、资源互通，共同推动乡村发展。</p>
        </div>

        <!-- 第5屏 -->
        <div v-show="currentTab === 4" class="banner_text_box">
          <h1>本地生活服务</h1>
          <p>平台提供自由发布信息，如搬家、跑腿、家政、家电维修等，让买家快速找到所需服务，卖家精准触达目标客户，真正实现“买与卖”的便捷对接，让乡村生活更加高效、舒适。</p>
        </div>

        <!-- 导航 -->
        <div class="banner_tab">
          <span
          v-for="(tab, index) in tabs"
          :key="index"
          :class="{ selected: currentTab === index }"
          @click="onTabChange(index)">
            {{ tab }}
          </span>
        </div>
      </div>
    </div>

    <!-- 关于我们 -->
    <div class="about_h5">
      <img src="../assets/images/icon_logo.svg">
      <span>关于我们</span>
      <p>村兴通是一个乡村信息共享平台，提供信息发布和查询。以数字化连接乡村，实现各个乡村信息共享，推动产业发展。还提供本地生活服务，丰富的类目，覆盖多数生活场景；买家便捷的获取服务信息，卖家精准的获取客源，让买和卖轻松达成。</p>
      <div class="about_contact_h5">
        <div class="contact_item_h5">电话：18923834678</div>
        <div class="contact_item_h5">邮箱：chencheng@izzxq.com</div>
      </div>
    </div>

    <!-- 可行性分析 -->
    <div class="analysis_h5">
      <div class="analysis_title_h5">
        <div class="analysis_title_l_h5"></div>
        <span>模式可行性分析</span>
        <div class="analysis_title_r_h5"></div>
      </div>

      <div class="analysis_content_h5">
        <div v-for="item in analysis" :key="item.index" class="analysis_content_item_h5">
          <span>{{ item.title }}</span>
          <p>{{ item.content }}</p>
        </div>
      </div>
    </div>

    <!-- 底部 -->
    <div class="qrcode_h5">
      <img src="../assets/images/h5_qrcode.png">
      <span>©村兴通 - 深圳智在向前科技有限公司</span>
      <a href="https://beian.miit.gov.cn/" target="_blank">
          <span>粤ICP备2022095797号-6</span>
        </a>
    </div>
  </div>
</template>

<script setup>
import TopNav from '@/components/TopNav.vue'
import { ref, onMounted, onUnmounted } from 'vue'

const analysis = ref([
  {
    title: '智能手机普及',
    content: '中老年人已经会使用智能手机，而小程序基于微信搭建，已培养成熟的用户习惯，降低小程序使用的学习成本。'
  },
  {
    title: '老龄化',
    content: '农村逐渐过渡向由年轻人组织村里的事务活动，对新事物的接受程度更开放，降低小程序的推广成本。'
  },
  {
    title: '政策扶持',
    content: '国家大力推动振兴乡村，当小程序进入成熟阶段，可以借助政策资源，进行全国普及，形成规模化。'
  },
  {
    title: '市场广阔',
    content: '小程序的目标用户为大众人群，为规模化奠定基础；以村子为入口，提供丰富全面的功能为村民提供便利。'
  }
])

// 自动切换标签
let timer = null // 定义定时器
const autoSwitchTab = () => {
  timer = setInterval(() => {
    currentTab.value = (currentTab.value + 1) % tabs.length // 切换到下一个标签
  }, 5000) // 5 秒切换一次
}

// 手动切换标签
const currentTab = ref(0)
const tabs = ['助力产业发展', '数字化新生活', '展现乡村魅力', '信息共享连接', '本地生活服务'] // 标签列表
const onTabChange = (index) => {
  currentTab.value = index // 更新当前选中标签
}

// 鼠标移入时暂停自动切换
const pauseAutoSwitch = () => {
  if (timer) {
    clearInterval(timer)
    timer = null
  }
}

// 鼠标移出时恢复自动切换
const resumeAutoSwitch = () => {
  if (!timer) {
    autoSwitchTab()
  }
}

// 组件挂载时启动定时器
onMounted(() => {
  autoSwitchTab()
})

// 组件卸载时清除定时器
onUnmounted(() => {
  if (timer) {
    clearInterval(timer)
  }
})

</script>

<style lang="scss">
@import "../style/home.scss";
</style>
