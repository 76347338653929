<template>
  <div class="container">
    <router-link to="/">
      <div class="logo">
        <img src="../../src/assets/images/logo.svg" />
      </div>
    </router-link>
    <div class="top_right">
      <div class="top_nav">
        <nav>
          <router-link to="/" :key="$route.fullPath"
            v-bind:class="{ active: $route.path === '/'}">产品介绍
          </router-link>
          <router-link to="/app" :key="$route.fullPath"
            v-bind:class="{ active: $route.path === '/app'}">小程序
          </router-link>
          <router-link to="/about" :key="$route.fullPath"
            v-bind:class="{active: $route.path === '/about'}">关于我们
          </router-link>
        </nav>
      </div>
      <div @click="showQrCode()" @click.stop class="top_btn">登录 / 注册</div>
    </div>

    <div @click="hideQrCode()" v-if="isShowQrCode" class="top_qrcode">
      <img src="../assets/images/img_xcx.jpg">
      <span>扫码使用小程序</span>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue'

const isShowQrCode = ref(false)

const showQrCode = () => {
  isShowQrCode.value = true
}

const hideQrCode = () => {
  isShowQrCode.value = false
}

</script>

<style scoped lang="scss">
@import "../style/topnav.scss";
</style>
